import { format, parse } from 'date-fns'
import {
  LanguageId,
  EventFieldsFragment,
  EventsDocument,
  NextEventsDocument,
  StickyEventDocument,
  QueryConjunction,
  QueryOperator,
  RelatedEventsDocument,
  FieldParagraphStickyEventTeaserFieldEventTag,
} from '../graphql/generated'
import { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables'
import { Link } from '~/types'
import { useTerms } from './useTerms'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export interface EventTeaser {
  id: number
  date: Date
  title: string
  location: string
  link: Link
  time: string
}

interface ParamRequest {
  document: TypedDocumentNode<any, any>
  cacheKey: string
  createVariables: () => any
}

interface ParamFilter {
  options: any
  values: any
}
export const createTransformResponse =
  (t: ComposerTranslation) =>
  (data: EventFieldsFragment[]): EventTeaser[] => {
    return (
      data?.map((item) => {
        return {
          id: item.nid || 0,
          time: item.fieldTimeWeekday || '',
          date: parse(item.fieldStartDate?.date, "yyyy-MM-dd HH:mm:ss 'UTC'", new Date()),
          title: item.fieldShortTitle ?? (item.title || ''),
          location: item.fieldPhysical
            ? `${item.fieldAddress?.addressLine1}, ${item.fieldAddress?.postalCode} ${item.fieldAddress?.locality}`
            : t('event.online'),
          link: {
            url: item.entityUrl?.path || '',
            target: '_self',
          },
        }
      }) || []
    )
  }

const createGetItems =
  (query: any, getVariables: any, transform: any) =>
  async (
    document: any = EventsDocument,
    cacheKey = 'events',
  ): Promise<{
    items: EventTeaser[]
    total: number
  }> => {
    const { data } = await useAsyncData(cacheKey, async () => {
      const { data } = await query({
        query: document,
        variables: getVariables(),
      })

      return {
        items: transform(data.nodeQuery?.entities as EventFieldsFragment[]),
        total: data.nodeQuery?.count || 0,
      }
    })

    return data.value ?? { items: [], total: 0 }
  }

export const useEvents = async (filter: ParamFilter, request: ParamRequest) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const perPage = 8
  const page = ref(1)
  const data = ref({
    items: [] as EventTeaser[],
    total: 0,
  })
  const items = computed(() => data.value.items)
  const pages = computed(() => Math.ceil(data.value.total / perPage))

  const getVariables = () => ({
    ...request.createVariables(),
    after: format(new Date(), 'yyyy-MM-dd'),
    offset: perPage * (page.value - 1),
    perPage,
    language: languageCODE.value as LanguageId,
  })
  const transform = createTransformResponse(t)
  const getItems = createGetItems(clients!.default.query, getVariables, transform)

  data.value = await getItems(request.document, request.cacheKey)

  watch([() => ({ ...filter.values }), page], async ([currentFilter], [previousFilter]) => {
    if (
      (currentFilter.type !== previousFilter.type || currentFilter.location !== previousFilter.location) &&
      page.value !== 1
    )
      return (page.value = 1) // triggers a new watch callback

    data.value = await getItems(request.document, request.cacheKey)
  })

  const years = computed(() =>
    items.value
      .map((event) => {
        return {
          event,
          year: event.date.getFullYear(),
        }
      })
      .reduce((years: Record<number, EventTeaser[]>, item) => {
        if (years[item.year] === undefined) {
          years[item.year] = [] as EventTeaser[]
        }
        years[item.year] = [...years[item.year], item.event]
        return years
      }, {}),
  )
  const noResults = computed(() => items.value.length === 0)
  return { years, page, pages, noResults } as const
}

export const useEventsNext = async (infoEventTypeIds: string[]) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const { terms } = await useTerms(['event_info_type'])

  const transformResponse = createTransformResponse(t)

  const getVariables = () => ({
    after: format(new Date(), 'yyyy-MM-dd'),
    currentId: '0',
    count: 3,
    promoted: ['1'],
    infoEventType: infoEventTypeIds.length ? infoEventTypeIds : terms.value.event_info_type.map((term) => term.id),
    conjunction: (infoEventTypeIds.length ? 'AND' : 'OR') as QueryConjunction,
    nullOperator: (infoEventTypeIds.length ? 'IS_NOT_NULL' : 'IS_NULL') as QueryOperator,
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`next-events`, async () => {
    const { data } = await clients!.default.query({
      query: NextEventsDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const items = computed(() => data.value?.items)

  return { items } as const
}

export const useEventsRelated = async ({ institutionId = ['0'], currentEventId = ['0'] }) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()

  const transformResponse = createTransformResponse(t)

  const getVariables = () => ({
    institutionId,
    currentId: currentEventId,
    after: format(new Date(), 'yyyy-MM-dd'),
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`events-related`, async () => {
    const { data } = await clients!.default.query({
      query: RelatedEventsDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const items = computed(() => data.value?.items)

  return { items } as const
}

export const useStickyEvent = async (entity: FieldParagraphStickyEventTeaserFieldEventTag | null) => {
  const { languageCODE } = useLocale()
  const { t } = useI18n()
  const { clients } = useApollo()
  if (!entity)
    return {
      items: [],
    }

  const transformResponse = createTransformResponse(t)
  const selectedEventTag = entity ? [`${entity.entity?.tid}`] : ''

  const getVariables = () => ({
    after: '',
    currentId: '0',
    count: 1,
    eventTag: selectedEventTag ? selectedEventTag : '',
    language: languageCODE.value as LanguageId,
  })

  const { data } = await useAsyncData(`sticky-event`, async () => {
    const { data } = await clients!.default.query({
      query: StickyEventDocument,
      variables: getVariables(),
    })
    if (!data) return { items: [] }

    return {
      items: transformResponse(data.nodeQuery?.entities as EventFieldsFragment[]),
    }
  })

  const eventItem = computed(() => data.value?.items[0] as EventTeaser)

  return { eventItem } as const
}
